/* Remove Roboto import since we're using Segoe UI */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */

body, button, input, textarea, select, code {
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Arial, sans-serif; /* Segoe UI with fallbacks */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Keep Roboto Flex if needed for specific cases */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght,wdth,GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,slnt@8..144,100..1000,1..150,0..1,0..128,0..1,0..1,0..1,0..1,0..1,0..1,0..1,-10..0&display=swap');
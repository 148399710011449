/* Remove border and shadow from the outer container */
.div-2, .div-3, .bottom-section {
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove shadow */
  padding: 0 !important; /* Remove padding if it creates spacing */
  margin: 0 !important; /* Remove margin if it creates spacing */
}

.free-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #1890ff; /* Blue color */
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 1;
}


/* Premium Badge - Clean Version */
.premium-badge {
  position: absolute;
  top: 10px;
  right: 20px;
  background: linear-gradient(135deg, #0077be 0%, #00a1d6 50%, #00c7eb 100%);
  color: #fff;
  padding: 8px 10px; /* Increased by 20% */
  border-radius: 6px;
  font-size: 12px; /* Increased by 20% */
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-transform: none; /* Allows mixed case */
  letter-spacing: 0.8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  overflow: hidden;
  transform: translateZ(0);
  line-height: 1.2; /* Better text handling */
}

/* Removed all ::before and ::after wave animations */

/* Slower Icon Animation */
.premium-badge .anticon-up-circle {
  color: #7FFFD4; /* Brighter aquamarine */
  filter: drop-shadow(0 1px 2px rgba(0, 80, 120, 0.3));
  animation: waveBounce 3.5s ease-in-out infinite; /* Slowed down */
  font-size: 16px; /* Larger icon */
}

@keyframes waveBounce {
  0%, 100% { 
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  30% { 
    transform: translateY(-4px) scale(1.03); /* Smaller movement */
    opacity: 0.95;
  }
  60% {
    transform: translateY(2px) scale(0.98); /* Reduced bounce */
  }
}

/* Text styling */
.premium-badge span {
  text-shadow: 0 1px 2px rgba(0, 40, 60, 0.4);
  letter-spacing: 0.5px;
  position: relative;
  top: 0;
  mix-blend-mode: screen; /* Better readability */
}

.feature-enabled-text {
  display: flex;
  align-items: center;
  margin-top: 16px; /* Spacing above the text */
  margin-bottom: 16px; /* Spacing below the text */
  color: #0066cc; /* Green color for the checkmark */
  font-size: 14px;
}

.feature-enabled-text .checkmark-icon {
  margin-right: 8px; /* Spacing between the checkmark and text */
}

/* Glowing Animation (unchanged) */
.ai-choice-container {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin: 1rem 0;
  box-shadow: none; /* Remove shadow */
}

.ai-choice-container::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(
    45deg,
    #000080,       /* Dark Blue */
    #0066cc,       /* Medium Blue */
    #0099ff,       /* Light Blue */
    #00ccff,       /* Ocean Cyan */
    #0099ff,       /* Light Blue */
    #0066cc,       /* Medium Blue */
    #000080        /* Dark Blue */
  );
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 14px;
  animation: glowing 20s linear infinite;
}

.ai-choice-container:hover::before {
  opacity: 1;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

/* Override Ant Design Card styles */
.no-shadow-card.ant-card {
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove shadow */
  background: transparent !important; /* Remove background if needed */
}








.no-shadow-card .ant-card-body {
  padding: 0 !important; /* Remove padding if it creates spacing */
  margin: 0 !important; /* Remove margin if it creates spacing */
}

/* Card Layout (updated for dynamic height and no shadow) */
.div-4 {
  min-height: 450px; /* Minimum height to ensure consistency */
  height: auto; /* Allow height to grow based on content */
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow to individual cards */
  padding: 20px;
}

/* Card Content (vertically center Lottie animation) */
.ai-card-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center; /* Vertically center the Lottie animation */
  height: 100%;
  flex-grow: 1;
}

/* Lottie Animation Container (30% width, vertically centered) */
.lottie-container {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center; /* Vertically center the animation */
  justify-content: center; /* Horizontally center the animation */
  flex-shrink: 0; /* Prevent shrinking */
}

/* Text Content (70% width, unchanged) */
.ai-text-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  height: 100%;
  flex-grow: 1;
  margin-bottom: 10px;
}

/* Title Container (unchanged) */
.ai-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.5rem 0;
}

.ai-title {
  width: 100%;
  margin-right: 16px;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #1a1a1a;
  font-weight: 600;
  font-size: 25px;
}

/* Description (scrollable if content overflows) */
.ai-description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
  line-height: 1.5;
  flex-grow: 1;
  overflow-y: auto; /* Enable scrolling */
  padding-right: 20px;
  margin-bottom: 20px;
}

/* Custom Scrollbar for Description */
.ai-description::-webkit-scrollbar {
  width: 6px;
}

.ai-description::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ai-description::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.ai-description::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Card Actions (stick to the bottom) */
.ai-card-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: auto; /* Push to the bottom */
}

/* Buttons (unchanged) */
.ai-setting-button, .ai-variant-button {
  cursor: pointer;
  color: #0066cc; /* Medium Blue */
  display: flex;
  align-items: center;
  font-size: 14px;
}

.ai-setting-button .anticon, .ai-variant-button .anticon {
  margin-right: 5px;
}
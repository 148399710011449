

.frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 10px;
    min-height: 2520px;
    min-width: 1086px;
    position: relative;
    width: 100%;
  }
  
  .frame .online-sotre-ais {
    align-items: flex-start;
    background-color: #ffffff;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .frame .div {
    background-color: #ffffff;
    height: 280px;
    position: relative;
    width: 1086px;
  }
  
  .frame .div-2 {
    background-color: #d9d9d940;
    height: 280px;
    position: relative;
    width: 1086px;
  }
  
  .frame .switch-instance {
    left: 1010px !important;
    position: absolute !important;
    top: 28px !important;
  }
  
  .frame .text-wrapper {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 31px;
    width: 468px;
  }
  
  .frame .enable-AI-to-enhance {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 90px;
    left: 438px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 59px;
    width: 464px;
  }
  
  .frame .design-component-instance-node {
    left: 1010px !important;
    position: absolute !important;
    top: 2268px !important;
  }
  
  .frame .text-wrapper-2 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 2267px;
    width: 462px;
  }
  
  .frame .AI-can-subtly-refine {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 80px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 2295px;
    width: 464px;
  }
  
  .frame .switch-2 {
    left: 1010px !important;
    position: absolute !important;
    top: 1988px !important;
  }
  
  .frame .text-wrapper-3 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1987px;
    width: 462px;
  }
  
  .frame .p {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 100px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 2015px;
    width: 464px;
  }
  
  .frame .switch-3 {
    left: 1010px !important;
    position: absolute !important;
    top: 1428px !important;
  }
  
  .frame .text-wrapper-4 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 442px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1427px;
    width: 462px;
  }
  
  .frame .improve-your-product {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 100px;
    left: 442px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1457px;
    width: 464px;
  }
  
  .frame .switch-4 {
    left: 1010px !important;
    position: absolute !important;
    top: 1708px !important;
  }
  
  .frame .text-wrapper-5 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 444px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1707px;
    width: 462px;
  }
  
  .frame .text-wrapper-6 {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 60px;
    left: 444px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1735px;
    width: 464px;
  }
  
  .frame .switch-5 {
    left: 1010px !important;
    position: absolute !important;
    top: 1148px !important;
  }
  
  .frame .text-wrapper-7 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1147px;
    width: 462px;
  }
  
  .frame .AI-can-fine-tune-the {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 80px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 1175px;
    width: 464px;
  }
  
  .frame .switch-6 {
    margin-bottom: -0.5px !important;
    margin-top: -0.5px !important;
  }
  
  .frame .switch-7 {
    height: 21px !important;
    left: 1010px !important;
    position: absolute !important;
    top: 868px !important;
  }
  
  .frame .text-wrapper-8 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 442px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 867px;
    width: 462px;
  }
  
  .frame .text-wrapper-9 {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 108px;
    left: 442px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 895px;
    width: 464px;
  }
  
  .frame .switch-8 {
    left: 1010px !important;
    position: absolute !important;
    top: 588px !important;
  }
  
  .frame .text-wrapper-10 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 587px;
    width: 462px;
  }
  
  .frame .AI-can-analyze-and {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 100px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 615px;
    width: 464px;
  }
  
  .frame .switch-9 {
    left: 1010px !important;
    position: absolute !important;
    top: 308px !important;
  }
  
  .frame .text-wrapper-11 {
    color: #000000d9;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    height: 20px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 307px;
    width: 462px;
  }
  
  .frame .text-wrapper-12 {
    color: #00000073;
    font-family: "Roboto-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 120px;
    left: 438px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 335px;
    width: 464px;
  }
  
  .frame .product-name {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 28px;
    width: 354px;
  }
  
  .frame .small-taglines-img {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 308px;
    width: 354px;
  }
  
  .frame .interactive-button {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 588px;
    width: 354px;
  }
  
  .frame .dynamic-product {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 868px;
    width: 354px;
  }
  
  .frame .packaging-text {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 1148px;
    width: 354px;
  }
  
  .frame .enhanced-technical {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 1428px;
    width: 354px;
  }
  
  .frame .ingredient-and {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 1708px;
    width: 354px;
  }
  
  .frame .customer-reviews {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 1988px;
    width: 354px;
  }
  
  .frame .brand-messaging {
    height: 224px;
    left: 28px;
    object-fit: cover;
    position: absolute;
    top: 2268px;
    width: 354px;
  }
  
  
  
.login-page-container {
  display: flex;
  height: 100vh;
  align-items: stretch;
  overflow: hidden; /* Ensures no vertical scrolling for the entire container */
  overflow-x: hidden; /* Ensures no horizontal scrolling for the entire container */
}

.external-content-container {
  width: 70%;
  height: 100%; /* Set to 100% height to match parent */
  overflow: hidden; /* Hide the scrollbar in iframe container */
}

.external-content-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.auth-container {
  width: 30%;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f0f0f0; /* Set background to light grey */
}

.auth-section {
  margin: 0 auto;
}

.hidden {
  display: none;
}

/* Inputs and Buttons */
.ant-input-lg, .ant-input-affix-wrapper-lg {
  height: auto;
  padding: 16px;
  font-size: 22px;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-password {
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9;
}

.ant-input:hover,
.ant-input-affix-wrapper:hover,
.ant-input-password:hover,
.ant-input:focus, 
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-password input:focus,
.ant-input-password input:active {
  background-color: #ffffff;
  border-color: #d9d9d9;
  box-shadow: none;
}

/* Override browser autofill background color */
.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover, 
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input-password:-webkit-autofill,
.ant-input-password:-webkit-autofill:hover,
.ant-input-password:-webkit-autofill:focus,
.ant-input-password:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-text-fill-color: #000 !important;
}

/* Ensure smooth background-color transition for autofill */
.ant-input:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.ant-input-password:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

ant-input::placeholder,
.ant-input-password input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

/* Overrides for link text, not button styling */
/* ... other styles remain unchanged ... */

/* Resets link styles to appear as text links instead of buttons */
.ant-btn-link {
  height: auto;
  padding: 0;
  margin: 0;
  background: transparent;
  color: #ffffff; /* Set text color to black */
  border: none;
  box-shadow: none;
  font-size: inherit;
  line-height: inherit;
  display: inline;
}

.ant-btn-link:hover, .ant-btn-link:focus {
  color: #000; /* Color of text on hover or focus */
  background: transparent;
  border: none;
  text-decoration: underline;
}
.ant-btn {
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  padding: 6px 12px;
  background-color: #f0f0f0;
  color: #000000;
  border: none;
}

/* Media query for mobile devices */
/* ... all other styles from before ... */

/* Media query for mobile devices */
@media (max-width: 768px) {
  .login-page-container {
    flex-direction: column; /* Stack the sections vertically */
  }

  .external-content-container {
    display: none; /* Hide the left frame (iframe) on mobile devices */
  }

  .auth-container {
    width: 100%; /* Full width on mobile */
    padding: 10px; /* Decrease padding to 10px */
  }

  .auth-section {
    width: calc(100% - 20px); /* Full width minus padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* Modify the size of inputs and buttons */
  .ant-input, .ant-input-lg, .ant-input-affix-wrapper, .ant-input-affix-wrapper-lg, .ant-btn {
    font-size: 80%; /* Resize elements to be smaller */
    padding: 10px; /* Adjust padding to 10px */
  }

  .ant-btn {
    height: 40px; /* Resize button height */
  }

  .hidden {
    display: none !important;
    visibility: hidden !important;
  }
}

/* ... all other styles from before ... */


.main-title {
  font-weight: bold;
  font-size: 42px; /* Set font size to 42px */
  text-align: center;
  margin-bottom: 4px;
}

.secondary-text {
  display: block;
  font-size: 22px; /* Set font size to 22px */
  text-align: center;
  margin-bottom: 24px;
}


/* Add style to avoid vertical scrollbar on iframe */
.external-content-container iframe {
  .external-content-container,
  .external-content-container iframe {
    overflow: hidden; /* Hide both horizontal and vertical scrollbars */
  }}

hidden {
  display: none !important;
  visibility: hidden !important; /* Additional rule to ensure content is not seen */
}

.logout-button {
  color: #fff !important; /* This will ensure text is white */
  background-color:  #000000 !important; /* This will ensure the button has no background */
  border: none !important; /* Removes any border that may be applied */
}

.custom-menu .ant-menu-item-selected {
  background-color: #f5f5f5 !important; /* This is the light grey color */
}


/* Add this at the bottom of your LoginPage.css file */
@media (max-width: 768px) {
  .login-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* This vertically centers the child elements */
    height: 100vh; /* Full viewport height */
  }

  .external-content-container {
    display: none; /* Hide the iframe on mobile as mentioned */
  }

  .auth-container {
    margin: auto;
    width: 90%; /* Adjust based on your preference */
    max-width: 400px; /* Ensures the container doesn't get too wide on larger screens */
  }
}



.register-benefits {
  margin-bottom: 20px; /* This creates space between the benefits and the form */
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.register-benefits ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.register-benefits li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.register-benefits li:last-child {
  margin-bottom: 0;
}